@import '../../theme.scss';

.courselist-page {
  .courselist-header {

    .left-col,
    .right-col {
      display: flex;

      h1 {
        color: #7229BC80;
      }
    }

    .bg {
      width: 100%;
    }
  }

  .courselist-content {
    .agegroup-row {
      border-radius: 8px;
      background: rgba(51, 13, 99, 0.03);
      padding: 5px;
      max-width: 100%;
      overflow-x: auto;

      .age-group {
        padding: 13px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: rgba(51, 13, 99, 0.45);
        white-space: nowrap;

        &.active {
          background: white;
          color: $primary-color;
          box-shadow: 0px 4px 15px rgba(42, 26, 127, 0.1);
        }
      }
    }

    .load-more-btn {
      width: fit-content;
      background: rgba(22, 211, 143, 0.08);
      color: $secondary-color;
      width: 149px;
      height: 60px;
      cursor: pointer;
      margin-top: 45px;
    }

    .course-list-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }
}

@media screen and (min-width: 992px) {
  .courselist-page {
    .courselist-header {
      .bg {
        margin: 45px 0;
      }

      .left-col {
        h1 {
          font-size: 144px;
          line-height: 188px;
        }

        p {
          font-size: 47px;
          line-height: 64px;
          margin-left: 4px;
        }
      }
    }

    .courselist-content {
      padding-bottom: 62px;

      .agegroup-row {
        margin-bottom: 26px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .courselist-page {
    .courselist-header {
      .left-col {
        h1 {
          font-size: 64px;
          line-height: 90px;
        }

        p {
          font-size: 21px;
          line-height: 34px;
          margin-left: 4px;
        }
      }

      .bg {
        max-width: 330px;
        margin: 75px auto 41px auto;
      }
    }

    .courselist-content {
      padding: 32px 0 75px;

      .course-list-container {
        grid-template-columns: repeat(1, 1fr);
      }

      .agegroup-row {
        margin-bottom: 36px;
      }
    }
  }
}