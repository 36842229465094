@import '../../theme.scss';

.media-page {
    display: flex;
    flex-direction: column;
    color: $primary-color;
    padding: 97px 0 120px;

    .contactus-container {
        display: flex;
        flex-direction: column;

        .main-row {
            margin: 0;
            padding: 30px 0;
        }
    }
}