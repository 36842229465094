@import '../../../theme.scss';

.parentingstart-component {
    background-color: $primary-color;
    color: $white900;
    h1 {
        font-weight: 600;
    }
    h2 {
        font-weight: 400;
        margin-bottom: 10px;
    }
    .strong-text {
        color: $secondary-color;
    }
    .email-content {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
        font-size: 1.125rem;
    }
    .comment-area {
        font-style: italic;
        color: $gray300;
        font-size: 1.125rem;
    }
    .right-area {
        width: 100%;

        .right-image {
            width: 100%;
        }
    }
    .description-content {
        .description-item {
            margin: 5px 0 5px;
            .description {
                font-size: 1.125rem;
                font-weight: 300;
            }
        }
    }
    .buy-button {
        height: 60px;
        border: solid 2px $white900;
        background-color: $secondary-color;
        cursor: pointer;
        opacity: 0.9;
        &:hover {
            opacity: 1;
        }
        .price {
            font-size: 1.25rem;
            color: $white900;
            font-weight: 400;
            margin-top: 5px;
        }
        .price-des {
            font-size: 0.75rem;
            color: $white900;
            font-weight: 300;
        }

    }
    .Paperback-button {
        background-color: $third-color;
    }
}

@media screen and (min-width: 992px) {
    .parentingstart-component {
        padding: 0px 0 90px;
        h1 {
            font-size: 42px;
            line-height: 47px;
            margin-bottom: 30px;
        }
        h2 {
            font-size: 28px;
            line-height: 44px;
        }
        p {
            margin-top: 21px;
            font-size: 20px;
            line-height: 30px;
        }
        .description-content {
            padding: 30px 0 30px;
        }
        .buy-button-content {
            display: flex;
            .buy-button {
                width: 170px;            
            }
            .Paperback-button {
                margin-left: 30px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .parentingstart-component {
        padding: 0px 0 40px;
        h1 {
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 20px;
            line-height: 25px;
            text-align: center;
        }
        p {
            margin-top: 16px;
            font-size: 16px;
            line-height: 24px;
        }
        .comment-area {
            text-align: center;
        }
        .description-content {
            padding: 20px 0 20px;
        }
        .buy-button-content {
            
            .Paperback-button {
                margin-top: 30px;
            }
        }
    }
}