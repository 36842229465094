@import '../../../theme.scss';

.userstories-component {
    .load-more-btn {
        width: fit-content;
        background: rgba(22, 211, 143, 0.08);
        color: $secondary-color;
        width: 149px;
        height: 60px;
        cursor: pointer;
    }
}

@media screen and (min-width: 992px) {
    .userstories-component {
        padding: 70px 0 90px;
        .story-row {
            margin-bottom: 41px;
        }
        .load-more-btn {
            margin: 4px auto 0 auto;
        }
    }
}

@media screen and (max-width: 991px) {
    .userstories-component {
        padding: 54px 0 75px;
        .story-row {
            margin-bottom: 24px;
            .sub-col {
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .load-more-btn {
            margin: 21px auto 0 auto;
        }
    }
}