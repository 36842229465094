.tutor-component {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    .info-area {
        display: flex;
        width: 100%;
        position: relative;
        z-index: 1;
        .sub-info {
            width: calc(100% - 30px);
        }
        img {
            height: 240px;
            filter: grayscale(100%);
        }
        .tutor-info {
            padding: 20px 0 20px 30px;
            box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.08);
            display: flex;
            flex-direction: column;
            background: white;
            font-size: 15px;
            margin-left: auto;
            margin-top: -60px;

            .name {
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 4px;
            }
            .sub-text {
                color: #7B88A0;
                line-height: 22px;
            }
            .school {
                margin: 10px 0 8px;
                border-top: 1px solid #E7EBEE;
                border-bottom: 1px solid #E7EBEE;
                padding: 8px 0 12px;
                display: flex;
                align-items: center;
                .svg-icon {
                    margin-right: 10px;
                }
            }
        }
    }
}