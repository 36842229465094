@import '../../theme.scss';

.adssignup-page {
    background: white;
    .ads-img {
        width: 100%;
    }
    .left-col {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
    }
    .left-col, .right-col, .back-btn, .plan-select {
        animation: contentShow 0.3s ease-out;
    }
    .right-col {
        align-items: flex-start;
    }
    .ads-img {
        height: initial !important;
    }
    .primary-btn {
        text-transform: uppercase;
        &.full-btn {
            width: 100%;
        }
    }
    .btn-2 {
        &.loading {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .select-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        background: #F6F5F9;
        border-radius: 10px;
        margin-bottom: 15px;
        cursor: pointer;
        &:nth-child(even) {
            margin-right: 0;
        }
        &.active {
            border: 1.5px solid $secondary-color;
            .selected {
                display: flex;
            }
        }
        .selected {
            position: absolute;
            width: 22px;
            height: 22px;
            right: -1px;
            top: -1px;
            border-top-right-radius: 10px;
            background: $secondary-color;
            border-bottom-left-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            .svg-icon {
                fill: white;
                margin-left: 5px;
            }
        }
    }
    .item-container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .back-btn {
        cursor: pointer;
        color: $secondary-color;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        .svg-icon {
            margin-right: 5px;
        }
    }
    .bubble {
        box-shadow: 0 4px 30px rgba(42, 26, 127, 0.08);
        padding: 0 27px;
        font-size: 14px;
        line-height: 22px;
    }
    .schedule {
        background: #F6F5F9;
        border-radius: 10px;
        height: 167px;
    }
    .plan-select {
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .period-row {
            border-radius: 8px;
            background: #F6F5F9;
            padding: 5px;
            max-width: 100%;
            overflow-x: auto;
            .period {
                padding: 13px 0;
                min-width: 146px;
                border-radius: 8px;
                cursor: pointer;
                font-size: 14px;
                font-weight: bold;
                color: rgba(51, 13, 99, 0.45);
                white-space: nowrap;
                &.active {
                    background: white;
                    color: $primary-color;
                }
            }
        }
        .pricing-body {
            display: flex;
            width: 100%;
            .coding-class {
                box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.09);
                padding: 24px 30px 30px;
                .sub-header {
                    text-align: center;
                    border-bottom: 1px solid rgba(95, 70, 193, 0.1);
                    padding-bottom: 22px;
                    h2 {
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 21px;
                        text-transform: uppercase;
                    }
                    h3 {
                        font-size: 48px;
                        line-height: 50px;
                        color: #712ABC;
                        display: flex;
                        align-items: baseline;
                        span {
                            display: block;
                            font-size: 16px;
                            margin-left: 7px;
                            opacity: 0.4;
                            font-weight: 500;
                            &.ignore {
                                font-size: 28px;
                                text-decoration: line-through;
                                margin-right: 7px;
                            }
                        }
                    }
                }
                .sub-body {
                    padding-top: 24px;
                    .sub-item {
                        margin-bottom: 17px;
                        .svg-icon {
                            fill: #712ABC;
                            margin-right: 9px;
                        }
                        p {
                            font-weight: 500;
                            margin: 0;
                        }
                    }
                    .btn-link {
                        height: 55px;
                    }
                }
                &.private { .sub-body { .btn-link { background: rgba(22, 211, 143, 0.08); color: $secondary-color; } } }
                &.sibiling { .sub-header { h3 { color: #2986F8; } } .sub-body { .sub-item { .svg-icon { fill: #2986F8; } } } }
            }
        }
    }
    .detail-info {
        .sub-container {
            display: flex;
        }
        h3 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 11px;
        }
    }
    .input-box {
        margin-bottom: 15px;
        p { margin-bottom: 0; text-align: left;}
    }
    .flag-select__btn {
        padding: 0;
        margin: 0;
        outline: none;
        .flag-select__option {
            padding: 0;
            margin: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .adssignup-page {
        margin-top: -87px;
        padding-top: 15px;
        .main-content {
            padding-top: 50px;
        }
        h1 {
            font-size: 36px;
            line-height: 52px;
            margin-bottom: 12px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 18px;
        }
        .primary-btn {
            width: 300px;
            height: 60px;
        }
        .left-col {
            padding-top: 82px;
        }
        .select-item {
            width: calc(50% - 10px);
            height: 60px;
            margin-right: 20px;
            max-width: 300px;
        }
        .item-container {
            &.web-whole {
                flex-direction: column;
            }
        }
        .bubble {
            width: 50%;
            bottom: 0;
            right: 0;
            height: 92px;
            position: absolute;
        }
        .schedule {
            width: 429px;
        }
        .plan-select {
            .period-row {
                margin: 29px 0 45px;
            }
            .pricing-body {
                justify-content: center;
                .coding-class {
                    width: calc(50% - 22.5px);
                    max-width: 428px;
                    background: white;
                    margin-right: 45px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .detail-info {
            margin-top: -82px;
            max-width: 520px;
            .btn-link {
                height: 60px;
            }
            .sub-container {
                justify-content: space-between;
                .input-box {
                    width: calc((100% - 30px) / 3);
                }
            }
        }
        .input-box {
            height: 60px;
        }
    }
}

@media screen and (max-width: 991px) {
    .adssignup-page {
        margin-top: -70px;
        padding-top: 11px;
        .logo-area {
            display: flex;
            justify-content: center;
        }
        .main-content {
            padding-top: 44px;
        }
        h1 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 9px;
            text-align: center;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 22px;
        }
        .primary-btn {
            height: 55px;
        }
        .right-col {
            display: flex;
            justify-content: center;
        }
        .ads-img {
            max-width: 177px;
            margin-bottom: 35px;
        }
        .select-item {
            width: calc(50% - 7.5px);
            height: 55px;
            margin-right: 15px;
        }
        .item-container {
            &.whole {
                flex-direction: column;
                .select-item {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
        .back-btn {
            margin-left: auto;
            margin-right: auto;
        }
        .bubble {
            height: 97px;
        }
        .schedule {
            width: 335px;
            margin-left: auto;
            margin-right: auto;
        }
        .plan-select {
            .period-row {
                margin: 20px 0 30px;
            }
            .pricing-body {
                flex-direction: column;
                .coding-class {
                    width: 100%;
                    max-width: 100%;
                    background: white;
                    &:first-child {
                        margin-bottom: 45px;
                    }
                }
            }
        }
        .detail-info {
            .btn-link {
                height: 55px;
            }
            .sub-container {
                flex-direction: column;
            }
        }
        .input-box {
            height: 55px;
        }
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}