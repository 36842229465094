@import '../../../theme.scss';

.instructors-component {
    background: #F6F5F9CD;
    .instructor-section {
        border-bottom: 1px solid $border-color;
        .instructor-content {
            .photo-flow {
                position: relative;
                overflow: hidden;
                .tutor-photo {
                    width: 100%;
                    height: 100%;
                    border-top-left-radius: 10px;
                    &.inactive {
                        display: none;
                    }
                }
                .icon-btn {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 45px;
                    height: 45px;
                    cursor: pointer;
                    background: rgba(255, 255, 255, 0.1);
                    backdrop-filter: blur(14px);
                    .svg-icon {
                        fill: white;
                        fill-opacity: 0.6;
                    }
                    &.left {
                        left: 15px;
                    }
                    &.right {
                        right: 15px;
                    }
                }
                .tutor-info {
                    position: absolute;
                    height: 90px;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    color: white;
                    backdrop-filter: blur(12px);
                    padding: 22px 0 0 23px;
                    border-bottom-left-radius: 10px;
                    h2 {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 3px;
                    }
                    p {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
            .detail {
                .detail-row {
                    display: flex;
                    align-items: flex-start;
                }
            }
        }
        .logo-row {
            justify-content: space-between;
            margin-bottom: 15px;
            .university {
                width: calc(50% - 7.5px);
                height: 70px;
                &.active {
                    background: white;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .brand-section {
        .sub-col {
            .brand-explain {
                .btn-link {
                    width: 199px;
                    height: 55px;
                }
            }
        }
        .brand-row {
            width: 100%;
            .brand-container {
                border: 1px solid $border-color;
                &:last-child {
                    margin-right: 0;
                }
            }
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .instructors-component {
        h1 {
            font-size: 36px;
            line-height: 24px;
        }
        .instructor-section {
            padding: 90px 0;
            h1 {
                margin-bottom: 56px;
                text-align: center;
            }
            .instructor-content {
                width: 100%;
                height: 100%;
                background: white;
                border-radius: 10px;
                .photo-flow {
                    width: 407px;
                    height: 410px;
                    .tutor-photo {
                        border-bottom-left-radius: 10px;
                    }
                }
                .detail {
                    flex: 1;
                    height: 100%;
                    background: white;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 15px;
                    justify-content: space-between;
                    .detail-row {
                        img {
                            margin-right: 10px;
                            width: 20px;
                            height: 20px;
                        }
                        p {
                            font-weight: 500;
                            line-height: 17px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .brand-section {
            padding: 90px 0;
            .sub-col {
                position: relative;
                justify-content: center;
                .brand-explain {
                    margin-top: -20px;
                    h1 {
                        text-align: left;
                        margin-bottom: 30px;
                        line-height: 51px;
                    }
                }
                img {
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                }
            }
            .brand-row {
                .brand-container {
                    height: 70px;
                    width: calc((100% - 30px) / 3);
                    margin-right: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .instructors-component {
        h1 {
            font-size: 24px;
            text-align: center;
            line-height: 36px;
        }
        .instructor-section {
            padding: 67px 0 75px;
            h1 {
                margin-bottom: 41px;
            }
            .instructor-content {
                flex-direction: column;
                .photo-flow {
                    max-width: 335px;
                    width: 100%;
                    height: 338px;
                    border-top-right-radius: 10px;
                }
                .detail {
                    max-width: 335px;
                    width: 100%;
                    background: white;
                    padding: 23px 29px 17px 21px;
                    margin-bottom: 45px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    img {
                        margin-right: 15px;
                        width: 30px;
                        height: 30px;
                    }
                    .detail-row {
                        margin-bottom: 18px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .brand-section {
            padding: 68px 0 75px 0;
            .sub-col {
                .brand-explain {
                    align-items: center;
                    .btn-link {
                        margin: 34px 0  45px;
                    }
                }
            }
            .brand-row {
                .brand-container {
                    height: 65px;
                    width: calc(50% - 7.5px);
                    margin-right: 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .instructors-component {
        .instructor-section {
            .instructor-content {
                .detail {
                    padding: 40px 25px 45px 40px !important;
                    .detail-row {
                        img {
                            margin-right: 15px !important;
                            width: 30px !important;
                            height: 30px !important;
                        }
                        p {
                            line-height: 26px !important;
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }
    }
}

@keyframes slideLeftHide {
    0% { transform: translateX(0);}
    100% { transform: translateX(100%);}
}

@keyframes slideLeftShow {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

@keyframes slideRightHide {
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
}

@keyframes slideRightShow {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
}