@import '../../../theme.scss';

.whyStudyCoding-component {
    color: $primary-color;
    .right-img {
        width: 100%;
    }
    
    h2 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 20px;
    }
    .btn-link {
        cursor: pointer;
    }
}

@media screen and (min-width: 992px) {
    .whyStudyCoding-component {
        padding: 90px 0;
        
        h1 {
            font-size: 36px;
            line-height: 46px;
            padding-right: 15px;
            margin-bottom: 26px;
        }
        .btn-link {
            height: 60px;
            width: 227px;
        }
        .sub-col {
            justify-content: center;
        }
        .man-content {
            .man-name {
                display: none;
                font-size: 17px;
                font-weight: 600;
            }
            .bill-gates, .mark-zuckerberg{
                margin-right: 7px;
                margin-bottom: 7px;
                &:hover {
                    .man-name {
                        display: block;
                        margin-top: -25px;
                        margin-left: 10px;
                    }
                }
            }
            .steve-jobs, .elon-musk{
                &:hover {
                    .man-name {
                        display: block;
                        margin-top: -25px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .whyStudyCoding-component {
        padding: 67px 0 75px;
        
        .sub-col {
            align-items: center;
            margin-bottom: 45px;
        }
        h1 {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 29px;
            text-align: center;
        }
        h2 {
            text-align: center;
        }
        .man-img {
            width: 100%;
        }
        .btn-link {
            height: 55px;
            width: 207px;
        }
        .man-content {
            padding: 15px;
            .bill-gates, .steve-jobs, .mark-zuckerberg{
                margin-bottom: 7px;
            }
        }
    }
}