@import '../../theme.scss';

.storypreview-component {
    width: 100%;
    position: relative;
    padding-top: 20px;

    .right-mark {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px !important;
    }

    .sub-block {
        width: calc(100% - 30px);
        position: relative;
    }

    .thumb-container {
        .course-thumb {
            width: 100%;
            height: 100%;
        }
    }

    .info-container {
        background: white;
        margin: -60px 0 0 auto;
        box-shadow: 0 0 170px rgba(42, 26, 127, 0.2);
        h2, p, .tags {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
        }
        h2 {
            -webkit-line-clamp: 3;
            max-width: 100%;
        }
        p {
            -webkit-line-clamp: 2;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 11px;
        }
        .category {
            font-size: 12px;
            padding: 9px 9px 6px;
            color: #5F46C1;
            font-weight: 500;
            background: rgba(239, 236, 249, 0.61);
            width: fit-content;
        }
        .preview-footer {
            .tags {
                flex: 1;
                -webkit-line-clamp: 2;
                font-size: 14px;
            }
            .svg-icon {
                fill: white;
            }
            .btn-link {
                border-radius: 0;
                border-top-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .storypreview-component {
        max-width: 370px;
        .thumb-container {
            height: 235px;
        }
        .info-container {
            padding: 25px 5px 0 29px;
            h2 {
                font-size: 20px;
                line-height: 28px;
                // max-height: 84px;
                height: 84px;
            }
            .category {
                margin: 11px 0 16px;
            }
            p {
                height: 52px;
            }
            .preview-footer {
                margin-right: -5px;
                .tags {
                    max-width: calc(100% - 70px);
                }
                .btn-link {
                    width: 60px;
                    height: 60px;
                    margin-left: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .storypreview-component {
        min-width: 335px;
        .thumb-container {
            position: relative;
            padding-top: 75%;
            .course-thumb {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .info-container {
            padding: 29px 16px 0 29px;
            h2 {
                font-size: 18px;
                line-height: 26px;
            }
            .category {
                margin: 12px 0 15px;
            }
            .preview-footer {
                margin-right: -16px;
                .tags {
                    max-width: calc(100% - 65px);
                }
                .btn-link {
                    margin-left: auto;
                    width: 55px;
                    height: 55px;
                }
            }
        }
    }
}