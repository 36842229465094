@import '../../theme.scss';

.card-inputbox-container {
    width: 100%;
    border: 2px solid #E3E8EC;
    background: white;
    overflow: hidden;
    height: 40px;
    padding: 0 11px;
    border-radius: 4px;

    ::placeholder {
        color: #A0A0A0;
    }

    svg {
        fill: #C5CBD7;
    }
    
    .card-input-container {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 10px;
        font-size: 15px;

        &.no-icon {
            padding-left: 0;
        }
    }
}