@import '../../theme.scss';

.selectbox-component {
    // height: 100%;
    height: 60px;
    width: 100%;
    background: #F6F5F9;
    padding-left: 20px;
    position: relative;
    &.focused {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ::placeholder {
            color: #330D63;
        }
        .last-icon {
            transform: rotate(0);
        }
    }
    .svg-icon {
        fill: #A898BD;
        &:first-child {
            margin-right: 11px;
        }
    }
    ::placeholder {
        color: #A898BD;
    }
    .input-area {
        flex: 1;
        min-width: 100px;
        .upon-placeholder {
            font-size: 12px;
            line-height: initial;
            color: #A898BD;
        }
    }
    .custom-input {
        border: none;
        outline: none;
        background: inherit;
        padding-right: 11px;
        padding-left: 0;
        color: #330D63;
        flex: 1;
        line-height: normal;
    }
    .last-icon {
        margin-right: 20px;
        margin-left: 0;
        fill: #A898BD;
        cursor: pointer;
        transform: rotate(180deg);
    }
    ::-webkit-scrollbar-track {
        background: #F6F5F9;
    }
    .options {
        position: absolute;
        max-height: 200px;
        overflow-y: auto;
        width: 100%;
        left: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 5;
        .option {
            min-height: 40px;
            background: #F6F5F9;
            color: #A898BD;
            padding-left: 50px;
            cursor: pointer;
            &:hover {
                color: #330D63;
                background: #A898BD;
            }
        }
    }
}

.selectbox-container-error {
    border: 2px solid red;
}

@media screen and (min-width: 992px) {
    .selectbox-component {
        .custom-input {
            font-size: 16px;
        }
        .options {
            top: 60px;
        }
    }
}

@media screen and (max-width: 991px) {
    .selectbox-component {
        .custom-input {
            font-size: 14px;
        }
        .options {
            top: 55px;
        }
    }
}