@import '../../../theme.scss';

.aboutauthor-component {
    
}

@media screen and (min-width: 992px) {
    .aboutauthor-component {
        height: 800px;
        background: url('../../../Assets/Parenting/aboutAuth_bg.jpeg') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;
    }
}

@media screen and (max-width: 991px) {
    .aboutauthor-component {
        height: 300px;
        background: url('../../../Assets/Parenting/aboutAuth_bg.jpeg') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;

    }
}