@import '../../theme.scss';

.thankyou-page {
    background: white;
    .ads-img {
        width: 100%;
    }
    .left-col {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
    }
    .left-col, .right-col {
        animation: contentShow 0.3s ease-out;
    }
    .right-col {
        align-items: flex-start;
    }
    .ads-img {
        height: initial !important;
    }
    .primary-btn {
        text-transform: uppercase;
    }
    .calendly-inline-widget {
        overflow: hidden;
    }
    
}

@media screen and (min-width: 992px) {
    .thankyou-page {
        margin-top: -87px;
        padding-top: 15px;
        .main-content {
            padding-top: 50px;
        }
        h1 {
            font-size: 24px;
            line-height: 39px;
            margin-bottom: 12px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 18px;
        }
        .primary-btn {
            min-width: 300px;
            max-width: 620px;
            height: 60px;
        }
        .right-col {
            &.ads-margin {
                padding-top: 65px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .thankyou-page {
        margin-top: -70px;
        padding-top: 11px;
        .logo-area {
            display: flex;
            justify-content: center;
        }
        .main-content {
            padding-top: 44px;
        }
        h1 {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 9px;
            text-align: center;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 22px;
        }
        .primary-btn {
            height: 55px;
        }
        .right-col {
            display: flex;
            justify-content: center;
        }
        .ads-img {
            max-width: 177px;
            margin: 0 auto 35px auto;
        }
        .right-col {
            flex-direction: column;
        }
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}