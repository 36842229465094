@import '../../theme.scss';

.story-page {
    .story-header {
        .image-container {
            width: 100%;
            padding-top: 80%;
            position: relative;
            .thumb-img {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
            }
        }
        .right-col {
            justify-content: center;
            p {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .story-content {
        .main-content {
            width: 100%;
            ImageContainer {
                display: block;
                position: relative;
                width: 100%;
                padding-top: 60%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .share-area {
            .share-container {
                display: flex;
                .share-btn {
                    width: 30px;
                    height: 30px;
                    outline: none;
                    cursor: pointer;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
        .parent-area {
            position: relative;
            .parent-container {
                background: #F8F7FA;
                padding: 25px 30px 30px;
                .sub-item {
                    border-bottom: 1px solid #E9E5F4;
                }
                .parent-header {
                    padding-bottom: 25px;
                    .parent {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 11px;
                    }
                    h3 {
                        font-size: 20px;
                        line-height: 24px;
                        span {
                            display: block;
                            color: #5F46C1;
                            padding: 6px 7px;
                            line-height: initial;
                            font-size: 12px;
                            font-weight: normal;
                            background: #EFECF9;
                            border-radius: 6px;
                            margin-left: 12px;
                        }
                    }
                }
                .sub-group {
                    padding: 27px 0 25px;
                    h2 {
                        font-size: 16px;
                        margin-bottom: 13px;
                    }
                    .flag {
                        width: 38px;
                        height: 38px;
                    }
                    .custom-flag {
                        ::before, ::after {
                            display: none;
                        }
                        padding: 0;
                        .flag-select__btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 38px;
                            height: 38px;
                            margin: 0;
                            padding: 0;
                            .flag-select__option--placeholder {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0;
                                padding: 0;
                                width: 38px;
                                height: 38px;
                                .flag-select__option__icon {
                                    top: 0;
                                    width: 38px;
                                    height: 38px;
                                }
                            }
                        }
                    }
                    .complete-item {
                        margin-bottom: 5px;
                        .middot {
                            width: 4px;
                            height: 4px;
                            background: #5F46C1;
                            margin-right: 5px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .btn-link {
                    height: 60px;
                }
            }
        }
    }
    .relation-stories {
        background: #F8F7FA;
        h1 {
            text-align: center;
        }
    }
}

@media screen and (min-width: 992px) {
    .story-page {
        .story-header {
            background: #F8F7FA;
            .image-container {
                margin: 45px 0 -45px;
            }
            .right-col {
                h1 {
                    font-size: 46px;
                    line-height: 64px;
                    margin-bottom: 24px;
                }
            }
        }
        .story-content {
            padding: 83px 0 84px;
            .main-content {
                h2 {
                    font-size: 24px;
                    line-height: 34px;
                    margin-bottom: 29px;
                }
                p {
                    line-height: 26px;
                    margin-bottom: 31px;
                }
                ImageContainer {
                    margin-bottom: 40px;
                }
            }
            .share-area {
                position: relative;
                .share-container {
                    flex-direction: column;
                    position: absolute;
                    right: 15px;
                    top: 0;
                    .share-btn {
                        margin-bottom: 10px;
                    }
                    &.floating {
                        position: fixed;
                        top: 103px;
                        right: initial;
                    }
                }
            }
        }
        .relation-stories {
            padding: 92px 0 62px;
            h1 {
                font-size: 36px;
                line-height: 24px;
                margin-bottom: 24px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .story-page {
        .story-header {
            .image-container {
                margin: 75px 0 38px;
            }
            .right-col {
                align-items: center;
                h1 {
                    font-size: 24px;
                    line-height: 34px;
                    text-align: center;
                    margin-bottom: 33px;
                }
            }
        }
        .story-content {
            padding: 46px 0 75px;
            .main-content {
                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 14px;
                }
                p {
                    line-height: 26px;
                    margin-bottom: 36px;
                }
                ImageContainer {
                    margin-bottom: 39px;
                }
            }
            .share-area {
                .share-container {
                    justify-content: center;
                    margin-bottom: 70px;
                    .share-btn {
                        margin-right: 10px;
                    }
                }
            }
            .parent-area {
                margin-top: 66px;
            }
        }
        .relation-stories {
            padding: 63px 0 75px;
            h1 {
                font-size: 24px;
                line-height: 44px;
                margin-bottom: 9px;
            }
            .sub-col {
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}