@import '../../theme.scss';

.faqs-page {
    h1 {
        margin-bottom: 30px;
    }
    .avatar-row {
        justify-content: center;
        width: 100%;
        margin-bottom: 45px;
        img {
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .accordion {
        border-top: 1px solid #E7E4F3;
        &:last-child {
            border-bottom: 1px solid #E7E4F3;
        }
        &.second {
            .accordion-title {
                font-size: 16px;
            }
        }
        &.parent {
            &.active {
                padding-bottom: 0;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .faqs-page {
        padding: 64px 0 87px;
        h1 {
            font-size: 32px;
            line-height: 64px;
        }
        .container {
            max-width: 843px;
        }
        .avatar-row {
            img {
                max-width: 144px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .faqs-page {
        padding: 63px 0 75px;
        h1 {
            font-size: 24px;
            line-height: 44px;
        }
        .avatar-row {
            img {
                max-width: 101px;
                &.last-img {
                    margin-right: 0;
                }
            }
        }
    }
}