@import '../../../theme.scss';

.universityCarousel-component {
    .university-item {
        width: 172px;
        height: 70px;
        border: solid 1px $gray500;
        border-radius: 10px;
        .university-logo {
            width: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .universityCarousel-component {
        .university-item {
            width: 172px;
        }
    }
}

@media screen and (max-width: 991px) {
    .universityCarousel-component {
        .university-item {
            width: 100%;
        }
    }
}