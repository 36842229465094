.checkbox-component {
    background-color: #F6F5F9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    border: 2px solid #330D63;

    .svg-icon {
        display: flex;
        align-items: center;
        justify-self: center;
        fill: white;
    }
    &.checked {
        background: #16D38F;
        border: none;
    }
}