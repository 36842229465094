.checkout-form {
    margin-bottom: 10px;
    .check-error {
        color: red;
        font-size: 16px;
    }
    .StripeElement {
        background-color: #F6F5F9;
        padding: 5px 0;
        display: flex;
        align-items: center;
        padding: 0 10px 0 20px;
        border-radius: 10px;
        .__PrivateStripeElement {
            flex: 1;
        }
    }
}

@media screen and (min-width: 992px) {
    .checkout-form {
        .StripeElement {
            height: 60px;
        }
    }
}

@media screen and (max-width: 991px) {
    .checkout-form {
        .StripeElement {
            height: 55px;
        }
    }
}