@import '../../../theme.scss';

.monthlyplanarea-component {
    background: #F9F8FA;

    .btn-2 {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            color: white;
        }
    }

    h1 {
        text-align: center;
    }
    .first-col {
        position: relative;
    }
    .priceman {
        position: absolute;
        top: 36px;
        right: -70px;
    }
    .price-container {
        padding: 24px 30px 30px;
        background: white;
        height: 100%;
        .sub-title {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 21px;
            text-align: center;
            font-weight: bold;
        }
        .price {
            font-weight: bold;
            line-height: 50px;
            text-align: center;
            padding-bottom: 22px;
            border-bottom: 1px solid $border-color;
            margin-bottom: 23px;
            color: #712ABC;
            span {
                font-weight: normal;
                font-size: 16px;
                margin-left: 6px;
                color: rgba(113, 42, 188, 0.4);
            }
        }
        .price-row {
            margin-bottom: 18px;
            .check-icon {
                width: 17px;
                margin-right: 9px;
            }
            p {
                flex: 1;
                max-width: calc(100% - 26px);
                font-weight: 500;
            }
        }
        .btn-link {
            height: 60px;
            margin-top: auto;
        }
    }
    .benefit-col {
        .benefit {
            margin-bottom: 30px;
            .icon-area {
                width: 40px;
                height: 40px;
                background: white;
                margin-right: 15px;
                border-radius: 6px;
                box-shadow: 0 4px 20px rgba(42, 26, 127, 0.11);
            }
            p {
                max-width: calc(100% - 55px);
                flex: 1;
            }
        }
        .btn-link {
            width: 200px;
            height: 60px;
            background: rgba(22, 211, 143, 0.2);
            color: $secondary-color;
        }
    }
}

@media screen and (min-width: 992px) {
    .monthlyplanarea-component {
        padding: 94px 0;
        h1 {
            font-size: 36px;
            line-height: 24px;
            margin-bottom: 43px;
        }
        .price-container {
            .price {
                font-size: 48px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .monthlyplanarea-component {
        padding: 67px 0 70px;
        h1 {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 34px;
        }
        .price-container {
            .price {
                font-size: 44px;
            }
        }
        .benefit-col {
            margin-top: 25px;
        }
    }
}