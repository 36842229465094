@import '../../../theme.scss';

.join-component {
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
    }
    p {
        font-size: 1.125em;
        text-align: center;
    }
    .go-btn {
        width: 100%;
        height: 48px;
        background-color: $secondary-color;
        font-size: 22px;
        font-weight: 600;
        color: $white900;
        cursor: pointer;
    }
    input {
        height: 48px;
        width: 100%;
        border: solid 1px $gray500;
        padding: 0 5px;
    }
    .privacy {
        font-size: 12px;
        text-align: center;
    }
}

@media screen and (min-width: 992px) {
    .join-component {
        padding: 90px 0 90px;
        .main-content {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
            .submit-content {
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
                padding: 30px 0;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .join-component {
        padding: 60px 0 60px;
        .main-content {
            .submit-content {
                padding: 20px 0;
                .go-btn {
                    margin-top: 20px;
                }
            }
            
        }
    }
}