@import '../../../theme.scss';

.brand-component {
    .brand-area {
        border-bottom: 1px solid rgba(95, 70, 193, 0.1);
        .row {
            justify-content: space-between;
            margin: 0;
            img {
                height: 20px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .brand-component {        
        .brand-area {
            padding: 35px 0 35px;
        }
    }
}

@media screen and (max-width: 991px) {
    .brand-component {
        .brand-area {
            padding: 30px 0 30px;
        }
    }
}