$primary-color: #330D63; //rgb(51, 13, 99)
$secondary-color: #16D38F;
$third-color: #7B88A0;
$forth-color: #d5d7db;

$gray200: #F6F5F9CD;
$gray300: #F6F5F9;
$gray400: #f8f8f8;
$gray500: #ccc;
$gray600: #ededed;
$gray700: #F9F8FA;

$green500: #16D38F;
$green600: #1BD0A5;
$green600_10: rgba(27, 208, 165, 0.1);
$green700: #00FCEB;
$green800: #048758;
$white900: #FFF;

$blue300: #330D63;
$blue300_30: rgba(51, 13, 99, 0.3);
$blue400: #4E2CD2;
$blue500: #2986F8;
$blue600: #A580DF;
$blue700: #5F46C1;
$blue800: #2986F8;
$blue900: #4CA8EB;

$white900: #FFF;

$red700: #FF7D7D;

$border-color: rgba(95, 70, 193, 0.1);