@import '../../../theme.scss';

.welcome-component {
    h6 {
        font-weight: 600;
    }
    .welcome-tutor {
        background-color: $gray300;
        padding: 10px;
        border-radius: 5px;
        .welcome-header {
            
        }
        .gradient-header {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                background-image: linear-gradient(transparent, $gray300);
            }
        }
    }
    .requirements-row {
        display: flex;
    }

}

@media screen and (min-width: 992px) {
    .welcome-component {
        p {
            font-size: 16px;
            margin-top: 20px;
        }
        .welcome-tutor {
            
        }
        
    }
}

@media screen and (max-width: 991px) {
    .welcome-component {
        p {
            font-size: 14px;
            margin-top: 15px;
        }
        
    }
}