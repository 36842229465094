@import '../../theme.scss';

.enroll2-page {
    background: #E5E5E5;
    min-height: 100vh;
    padding-bottom: 45px;

    .btn-2 {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .main-content {
        display: flex;
        animation: contentShow 0.3s ease-out;
        .payment-area {
            box-shadow: -0 4px 30px rgba(42, 26, 127, 0.09);
            padding: 13px 30px 30px;
            background: white;
            width: 100%;
            h3 {
                font-size: 16px;
                line-height: 24px;
                margin: 4px 0 11px;
            }
            .select-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                background: #F6F5F9;
                border-radius: 10px;
                margin-bottom: 15px;
                cursor: pointer;
                &.active {
                    border: 1.5px solid $secondary-color;
                    .selected {
                        display: flex;
                    }
                }
                .selected {
                    position: absolute;
                    width: 22px;
                    height: 22px;
                    right: -1px;
                    top: -1px;
                    border-top-right-radius: 10px;
                    background: $secondary-color;
                    border-bottom-left-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: none;
                    .svg-icon {
                        fill: white;
                        margin-left: 5px;
                    }
                }
            }
            .sub-container {
                display: flex;
            }
            .check-area {
                margin-bottom: 30px;
                .check-label {
                    margin-left: 10px;
                    a {
                        color: $secondary-color;
                    }
                }
            }
            .input-box, .select-box {
                margin-bottom: 15px;
                p { margin-bottom: 0; text-align: left;}
            }
            p {
                font-size: 16px;
                margin-bottom: 15px;
                width: fit-content;
            }
            .referral {
                cursor: pointer;
                color: $secondary-color;
            }
        }
        .coding-class {
            box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.09);
            padding: 24px 30px 22px;
            background: white;
            position: relative;
            width: 100%;
            height: fit-content;
            .plan-container {
                position: absolute;
                background: url('../../Assets/Course/gradebg.png') no-repeat;
                background-size: 100% 100%;
                top: 10px;
                left: -5px;
                width: 80px;
                height: 30px;
                color: white;
                padding-bottom: 5px;
                font-size: 9px;
                font-weight: bold;
                text-transform: uppercase;
            }
            .sub-header {
                text-align: center;
                border-bottom: 1px solid rgba(95, 70, 193, 0.1);
                padding-bottom: 22px;
                h2 {
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 21px;
                    text-transform: uppercase;
                }
                h3 {
                    font-size: 48px;
                    line-height: 50px;
                    color: #712ABC;
                    display: flex;
                    align-items: baseline;
                    span {
                        display: block;
                        font-size: 16px;
                        margin-left: 7px;
                        opacity: 0.4;
                        font-weight: 500;
                        &.ignore {
                            font-size: 28px;
                            text-decoration: line-through;
                            margin-right: 7px;
                        }
                    }
                }
            }
            .sub-body {
                padding-top: 24px;
                &.has-border {
                    border-bottom: 1px solid rgba(95, 70, 193, 0.1);
                }
                h4 {
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 16px;
                }
                .sub-item {
                    margin-bottom: 17px;
                    display: flex;
                    align-items: flex-start;
                    .circle {
                        width: 4px;
                        height: 4px;
                        background: #712ABC;
                        margin: 11px 6px 0 0;
                    }
                    .svg-icon {
                        fill: #712ABC;
                        margin: 6px 9px 0 0;
                    }
                    p {
                        font-weight: 500;
                        margin: 0;
                        text-align: left;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .enroll2-page {
        margin-top: -87px;
        padding-top: 15px;
        .logo-area {
            justify-content: center;
            display: flex;
        }
        .main-content {
            padding-top: 50px;
            justify-content: center;
            .payment-area {
                max-width: 579px;
                margin-right: 44px;
                h1 {
                    font-size: 36px;
                    line-height: 64px;
                }
                .sub-container {
                    justify-content: space-between;
                    .select-item, .input-box {
                        width: calc((100% - 30px) / 3);
                    }
                }
                .select-item, .input-box, .select-box, .btn-link {
                    height: 60px;
                }
            }
            .coding-class {
                max-width: 346px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .enroll2-page {
        margin-top: -70px;
        padding-top: 11px;
        .logo-area {
            display: flex;
            justify-content: center;
        }
        .main-content {
            padding-top: 44px;
            flex-direction: column-reverse;
            .payment-area {
                h1 {
                    font-size: 24px;
                    line-height: 40px;
                    margin-bottom: 8px;
                }
                .sub-container {
                    flex-direction: column;
                }
                .btn-link, .input-box, .select-box, .select-item {
                    height: 55px;
                }
            }
            .coding-class {
                margin-bottom: 45px;
            }
        }
        
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 22px;
        }
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}