@import '../../theme.scss';

.header-component {
  position: fixed;
  z-index: 10;
  color: white;
  background: white;
  width: 100%;
  box-shadow: 0px 4px 45px rgba(42, 26, 127, 0.07);
  top: 0px; //For push notification

  .header-container {
    height: 100%;
    justify-content: space-between;

    .web-menu {
      .text-menu {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .text-menu {
    margin-right: 39px;
    padding-bottom: 4px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $secondary-color;
      padding-top: 2px;
      border-bottom: 2px solid $secondary-color;
    }
  }

  .box-menu {
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    color: white;
    padding: 8px 15px;
    background: $secondary-color;
    border-radius: 10px;
  }

  .menu-item {
    color: $primary-color;
    font-weight: 500;
    font-size: 16px;
  }

  .svg-icon {
    fill: $primary-color;
  }

  .logo-inactive {
    display: none;
  }

  &.inactive {
    background: transparent;
    box-shadow: none;
    top: 40px; //For push notification

    .logo-active {
      display: none;
    }

    .logo-inactive {
      display: block;
    }

    .menu-item {
      color: white;

      &:hover {
        color: $secondary-color;
      }
    }

    .box-menu {
      color: $primary-color;
      background: white;
    }

    .svg-icon {
      fill: white;
    }

    .pricing-menu {
      .pricing-header {
        p {
          color: white;
        }
      }
    }
  }

  .pricing-menu {
    margin-right: 39px;
    position: relative;

    .pricing-header {
      cursor: pointer;
      padding-bottom: 4px;

      p {
        margin-right: 5px;
        color: $primary-color;
        font-weight: 500;
      }

      .svg-icon {
        transform: rotate(180deg);
      }
    }

    .menu-area {
      position: absolute;
      width: 111px;
      left: -18px;
      box-shadow: 0px 4px 100px rgba(42, 26, 127, 0.2);
      border-radius: 10px;
      top: 30px;
      display: none;

      .svg-icon {
        fill: white !important;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
      }

      .sub-menu {
        width: 100%;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 10px;

        .sub-menu-item {
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: $primary-color;
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;

          &:hover {
            background: rgba(22, 211, 143, 0.1);
            color: $secondary-color;
          }

          &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    &:hover {
      .pricing-header {
        p {
          color: $secondary-color;
        }

        .svg-icon {
          fill: $secondary-color;
          transform: rotate(0);
        }
      }

      .menu-area {
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .header-component {
    height: 87px;

    .logo {
      margin-top: -14px;
    }
  }
}

@media screen and (max-width: 991px) {
  .header-component {
    height: 70px;

    .logo {
      margin-top: -11px;
    }
  }
}