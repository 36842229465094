@import '../../../theme.scss';

.groupcourses-component {
    h1 {
        text-align: center;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    .agegroup-row {
        border-radius: 8px;
        background: rgba(51, 13, 99, 0.03);
        padding: 5px;
        max-width: 100%;
        overflow-x: auto;
        .age-group {
            padding: 13px;
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: rgba(51, 13, 99, 0.45);
            white-space: nowrap;
            &.active {
                background: white;
                color: $primary-color;
                box-shadow: 0px 4px 15px rgba(42, 26, 127, 0.1);
            }
        }
    }
    .course-slider-row {
        width: 100%;
    }
    .owl-theme {
        .owl-stage-outer {
            .owl-stage {
                display: flex;
            }
        }
    }
    .tech-row {
        border-radius: 8px;
        background: rgba(51, 13, 99, 0.03);
        padding: 5px;
        margin-bottom: 10px;
        width: fit-content;
        .tech {
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: rgba(51, 13, 99, 0.45);
            width: 120px;
            height: 40px;
            &.active {
                background: white;
                color: $primary-color;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .groupcourses-component {
        padding-top: 89px;
        h1 {
            font-size: 36px;
            line-height: 24px;
            margin-bottom: 43px;
        }
        .owl-theme {
            .owl-stage-outer {
                padding: 25px 0 95px;
            }
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 40%;
                margin: 0;
                left: -30px;
                right: -30px;
                pointer-events: none;
                .owl-prev, .owl-next {
                    pointer-events: auto;
                    outline: none;
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .groupcourses-component {
        padding: 60px 0 75px;
        h1 {
            font-size: 24px;
            line-height: 44px;
            margin-bottom: 30px;
        }
        .owl-theme {
            .owl-stage-outer {
                padding: 25px 0 30px;
            }
            .owl-nav {
                margin: 0;
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 15px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 15px;
                }
            }
        }
    }
}