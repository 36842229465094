.userreviews-component {
    background: #F9F8FA;
    h1 {
        text-align: center;
    }
    .owl-theme {
        .owl-stage-outer {
            .owl-stage {
                display: flex;
            }
        }
    }
    .brand-row {
        justify-content: space-between;
        img {
            height: 20px;
        }
    }
}

@media screen and (min-width: 992px) {
    .userreviews-component {
        padding: 60px 0 80px;
        h1 {
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 36px;
        }
        .reviews-row {
            margin-bottom: 38px;
        }
        .owl-theme {
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                left: -25px;
                right: -25px;
                pointer-events: none;
                .owl-prev, .owl-next {
                    pointer-events: auto;
                    outline: none;
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .userreviews-component {
        padding: 60px 0 70px;
        h1 {
            font-size: 24px;
            line-height: 50px;
            margin-bottom: 27px;
        }
        .reviews-row {
            margin-bottom: 42px;
        }
        .owl-theme {
            .owl-nav {
                margin: 30px 0 0 0;
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 15px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 15px;
                }
            }
        }
    }
}