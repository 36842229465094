@import '../../theme.scss';

.medias-component {
    width: 100%;

    .owl-theme {
        .owl-stage-outer {
            .owl-stage {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .medias-component {
        .owl-theme {
            .owl-stage-outer {
                padding: 25px 0 95px;
            }
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 40%;
                margin: 0;
                left: -30px;
                right: -30px;
                pointer-events: none;
                .owl-prev, .owl-next {
                    pointer-events: auto;
                    outline: none;
                    &:hover {
                        background: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .medias-component {
        .owl-theme {
            .owl-stage-outer {
                padding: 25px 0 30px;
            }
            .owl-nav {
                margin: 0;
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 15px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 15px;
                }
            }
        }
    }
}