@import '../../theme.scss';

.footer-component {
    margin-top: auto;
    color: white;

    h2 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;
    }

    .link-list {
        .footer-link {
            margin-bottom: 13px;
            font-size: 16px;
            line-height: 28px;
            color: white;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .copy-right {
        font-size: 12px;
    }

    .social {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }

    .box-item {
        background: $secondary-color;
        max-width: 207px;
        height: 60px;
        width: 100%;
        font-weight: bold;
        color: white;
        font-size: 16px;
        line-height: 24px;
        border-radius: 10px;
        margin-top: 45px;
    }
}

@media screen and (min-width: 992px) {
    .footer-component {
        padding: 111px 0 26px;
        background: url('../../Assets/Home/footer-bg.png') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;
        .row {
            margin-bottom: 80px;
        }
    }
}

@media screen and (max-width: 991px) {
    .footer-component {
        padding: 70px 0 26px;
        background: url('../../Assets/Home/footer-mobile.png') no-repeat;
        background-position: center;
        background-size: cover;
        .row {
            .sub-col {
                margin-bottom: 36px;
                text-align: center;
                align-items: center;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .copy-right {
            margin-top: 35px;
            text-align: center;
        }
    }
}