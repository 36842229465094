@import '../../../theme.scss';

.getMoreInfo-component {
    color: $primary-color;
    background-color: $gray200;
    .right-img {
        width: 100%;
    }
    .stron-text {
        color: $secondary-color;
    }
    .roland-photo {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }
    .roland-name {
        font-size: 16px;
        font-weight: bold;
    }
    .roland-role {
        font-size: 12px;
    }
    .phone-input {
        padding: 0;
    }
    .have-computer-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .select-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            background: #F6F5F9;
            border-radius: 10px;
            height: 50px;
            width: calc(50% - 10px);
            cursor: pointer;
            &:nth-child(even) {
                margin-right: 0;
            }
            &.active {
                border: 1.5px solid $secondary-color;
                .selected {
                    display: flex;
                }
            }
            .selected {
                position: absolute;
                width: 22px;
                height: 22px;
                right: 0;
                top: 0;
                border-top-right-radius: 10px;
                background: $secondary-color;
                border-bottom-left-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                display: none;
                .svg-icon {
                    fill: white;
                    margin-left: 5px;
                }
            }
        }
    }
    .sub-btn {
        height: 50px;
        border-radius: 10px;
        background-color: $secondary-color;
        color: $white900;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
    }
}

@media screen and (min-width: 992px) {
    .getMoreInfo-component {
        padding: 90px 0;
        h1 {
            font-size: 36px;
            line-height: 46px;
            padding-right: 15px;
            margin-bottom: 26px;
        }
        h2 {
            font-size: 24px;
            line-height: 24px;
            text-align: center;
        }
        .sub-col {
            justify-content: center;
        }
        .right-col {
            position: relative;
            .from-content {
                position: absolute;
                top: 70px;
                left: 45px;
                z-index: 20;
                width: 50%;
                margin-left: 20px;
                .input-form {
                    margin-bottom: 15px;
                    height: 50px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .getMoreInfo-component {
        padding: 67px 0 75px;
        
        h1 {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 29px;
            text-align: center;
        }
        h2 {
            font-size: 20px;
            text-align: center;
        }
        .right-col {
            margin-top: 20px;
            .from-content {
                background-color: $white900;
                border-radius: 10px;
                padding: 15px;
                margin-top: 20px;
                .input-form {
                    margin-bottom: 10px;
                    height: 55px;
                    width: 100%;
                }
            }
        }
    }
}