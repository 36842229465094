@import '../../theme.scss';

.enroll-page {
    background: white;
    .ads-img {
        width: 100%;
    }
    .left-col {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        position: relative;
    }
    .left-col, .right-col, .back-btn, .plan-select {
        animation: contentShow 0.3s ease-out;
    }
    .right-col {
        align-items: flex-start;
    }
    .ads-img {
        height: initial !important;
    }
    .primary-btn {
        text-transform: uppercase;
    }
    .select-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        background: #F6F5F9;
        border-radius: 10px;
        margin-bottom: 15px;
        cursor: pointer;
        &:nth-child(even) {
            margin-right: 0;
        }
        &.active {
            border: 1.5px solid $secondary-color;
            .selected {
                display: flex;
            }
        }
        .selected {
            position: absolute;
            width: 22px;
            height: 22px;
            right: -1px;
            top: -1px;
            border-top-right-radius: 10px;
            background: $secondary-color;
            border-bottom-left-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            .svg-icon {
                fill: white;
                margin-left: 5px;
            }
        }
    }
    .item-container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .back-btn {
        cursor: pointer;
        color: $secondary-color;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 10px;
        width: fit-content;
        .svg-icon {
            margin-right: 5px;
        }
    }
    .bubble {
        box-shadow: 0 4px 30px rgba(42, 26, 127, 0.08);
        padding: 0 27px;
        font-size: 14px;
        line-height: 22px;
    }
    .schedule {
        background: #F6F5F9;
        border-radius: 10px;
        height: 167px;
    }
    .coding-class {
        box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.09);
        padding: 24px 30px 30px;
        background: white;
        position: relative;
        margin: 10px;
        .plan-container {
            position: absolute;
            background: url('../../Assets/Course/gradebg.png') no-repeat;
            background-size: 100% 100%;
            top: 10px;
            left: -5px;
            width: 80px;
            height: 30px;
            color: white;
            padding-bottom: 5px;
            font-size: 9px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .sub-header {
            text-align: center;
            border-bottom: 1px solid rgba(95, 70, 193, 0.1);
            padding-bottom: 22px;
            h2 {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 21px;
                text-transform: uppercase;
            }
            h3 {
                font-size: 48px;
                line-height: 50px;
                color: #712ABC;
                display: flex;
                align-items: baseline;
                span {
                    display: block;
                    font-size: 16px;
                    margin-left: 7px;
                    opacity: 0.4;
                    font-weight: 500;
                    &.ignore {
                        font-size: 28px;
                        text-decoration: line-through;
                        margin-right: 7px;
                    }
                }
            }
        }
        .sub-body {
            padding-top: 24px;
            &.has-border {
                border-bottom: 1px solid rgba(95, 70, 193, 0.1);
            }
            h4 {
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 16px;
            }
            .sub-item {
                margin-bottom: 17px;
                display: flex;
                align-items: flex-start;
                &.sub {
                    align-items: center;
                }
                .circle {
                    width: 4px;
                    height: 4px;
                    background: #712ABC;
                    margin-right: 6px;
                }
                .svg-icon {
                    fill: #712ABC;
                    margin: 6px 9px 0 0;
                }
                p {
                    font-weight: 500;
                    margin: 0;
                    text-align: left;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
            .btn-link {
                height: 55px;
                &.disabled {
                    opacity: 0.5;
                }
            }
        }
        &.private { .sub-body { .btn-link { background: rgba(22, 211, 143, 0.08); color: $secondary-color; } } }
        &.sibiling { .sub-header { h3 { color: #2986F8; } } .sub-body { .sub-item { .svg-icon { fill: #2986F8; } } } }
    }
    .detail-info {
        margin-left: 10px;
        .sub-container {
            display: flex;
        }
        h3 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 11px;
        }
        .btn-link {
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
    .input-box, .select-box {
        margin-bottom: 15px;
        p { margin-bottom: 0; text-align: left;}
    }
    .progress-bar {
        width: 100%;
        height: 4px;
        background: #F6F5F9;
        border-radius: 5px;
        .current-progress {
            height: 4px;
            border-radius: 5px;
            background: #5F46C1;
        }
    }
    .sub-section {
        .sub-btn-area {
            display: flex;
            .primary-btn {
                min-width: 100px;
                margin-bottom: 15px;
            }
            .skip {
                background: rgba(22, 211, 143, 0.08); color: $secondary-color;
            }
        }
        .warning {
            color: #330D63;
            white-space: nowrap;
            font-size: 14px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 23px;
        }
    }
    .check-area {
        margin-bottom: 30px;
        .check-label {
            margin-left: 10px;
            a {
                color: $secondary-color;
            }
        }
    }
    .flag-select__btn {
        padding: 0;
        margin: 0;
        outline: none;
        .flag-select__option {
            padding: 0;
            margin: 0;
        }
    }
    .period-row {
        border-radius: 8px;
        background: rgba($color: #2986F8, $alpha: 0.3);
        padding: 5px;
        max-width: 100%;
        overflow-x: auto;
        .period {
            padding: 13px 0;
            min-width: 146px;
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: white;
            white-space: nowrap;
            &.active {
                background: white;
                color: #2986F8;
            }
        }
    }
    .pricing-body {
        width: 100%;
        display: flex;
        margin-top: 30px;
    }
}

@media screen and (min-width: 992px) {
    .enroll-page {
        margin-top: -87px;
        padding-top: 15px;
        .main-content {
            padding-top: 50px;
        }
        h1 {
            font-size: 36px;
            line-height: 52px;
            margin-bottom: 12px;
        }
        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 18px;
        }
        .primary-btn {
            min-width: 300px;
            max-width: 620px;
            height: 60px;
        }
        .select-item {
            width: calc(50% - 10px);
            height: 60px;
            margin-right: 20px;
            max-width: 300px;
        }
        .item-container {
            &.web-whole {
                flex-direction: column;
            }
        }
        .bubble {
            width: 50%;
            bottom: 0;
            right: 0;
            height: 92px;
            position: absolute;
        }
        .schedule {
            width: 429px;
        }
        .right-col {
            &.ads-margin {
                padding-top: 65px;
            }
        }
        .detail-info {
            max-width: 520px;
            .btn-link {
                height: 60px;
            }
            .sub-container {
                justify-content: space-between;
                .input-box {
                    width: calc((100% - 30px) / 3);
                }
            }
        }
        .sub-section {
            max-width: 300px;
            .sub-btn-area {
                .continue {
                    max-width: 300px;
                    flex: 1;
                }
                .skip {
                    width: 121px;
                    margin-left: 15px;
                }
            }
        }
        .input-box, .select-box {
            height: 60px;
        }
        .progress-bar {
            max-width: 378px;
            margin-bottom: 29px;
        }
        .sub-container {
            .select-item {
                margin-right: 0;
                width: calc((100% - 30px) / 3);
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .enroll-page {
        margin-top: -70px;
        padding-top: 11px;
        .logo-area {
            display: flex;
            justify-content: center;
        }
        .main-content {
            padding-top: 44px;
        }
        h1 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 9px;
            text-align: center;
        }
        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 22px;
        }
        .primary-btn {
            height: 55px;
        }
        .right-col {
            display: flex;
            justify-content: center;
        }
        .ads-img {
            max-width: 177px;
            margin: 0 auto 35px auto;
        }
        .select-item {
            width: calc(50% - 7.5px);
            height: 55px;
            margin-right: 15px;
        }
        .item-container {
            &.whole {
                flex-direction: column;
                .select-item {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
        .back-btn {
            margin-left: auto;
            margin-right: auto;
        }
        .bubble {
            height: 97px;
        }
        .schedule {
            width: 335px;
            margin-left: auto;
            margin-right: auto;
        }
        .coding-class {
            margin-bottom: 45px;
        }
        .detail-info {
            .btn-link {
                height: 55px;
            }
            .sub-container {
                flex-direction: column;
            }
        }
        .input-box, .select-box {
            height: 55px;
        }
        .sub-section {
            .sub-btn-area {
                flex-direction: column;
            }
        }
        .right-col {
            flex-direction: column;
        }
        .sub-container {
            .select-item {
                width: 100%;
                margin-right: 0;
            }
        }
        .progress-bar {
            margin-bottom: 36px;
        }
    }
}

@keyframes contentShow {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0); 
    }
}