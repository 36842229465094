@import '../../../theme.scss';

.nationalMedia-component {
    color: $primary-color;
    h1 {
        line-height: 24px;
    }
    h1, h2 {
        text-align: center;
    }
    p {
        font-size: 16px;
    }
    .left-area {
        .video-prview-img {
            width: 100%;
        }
    }
    .right-area {
        .right-content {
            background-color: $gray200;
            .logo-content {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                // background-color:$primary-color;
                border: solid 1px $primary-color;
                padding: 3px;
                .brand {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .logo-name {
                font-size: 16px;
                font-weight: bold;
            }
            .logo-des {
                font-size: 12;
            }
        }
    }
    .logo-list-container {
        display: flex;
        justify-content: space-between;
        .logo-item {
            cursor: pointer;
            border: solid 1px $gray500;
            border-radius: 5px;
            padding: 3px;
        }
        .active-logo {
            border: solid 3px $primary-color;
        }
    }
}

@media screen and (min-width: 992px) {
    .nationalMedia-component {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;

        padding: 90px 0;
       
        h1 {
            font-size: 36px;
            margin-bottom: 56px;
        }
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 40px 0 9px
        }
        p {
            line-height: 26px;
        }
        .left-area {
            padding: 0;
            .video-prview-img {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .play-icon {
                cursor: pointer;
                position: absolute;
                z-index: 2;
                left: calc(50% - 23px);
                top: calc(50% - 39px);
                width: 60px;
            }
        }
        .right-area {
            background-color: $gray200;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            .right-content {
                padding: 30px 40px 20px 20px;                
            }
        }
        .logo-list-container {
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 991px) {
    .nationalMedia-component {
        padding: 67px 0 75px;
        
        h1 {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 29px;
        }

        .left-area {
            .video-prview-img {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .play-icon {
                position: absolute;
                z-index: 10;
                left: calc(50% - 43px);
                top: calc(50% - 29px);
                width: 60px;
            }
        }
        .right-area {
            .right-content {
                padding: 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
        .logo-list-container {
            margin-top: 30px;
            .logo-item {
                margin-right: 5px;
                .company-logo {
                    width: 60%;
                }
            }
        }
    }
}