.reviewstart-component {
    .container {
        display: flex;
        align-items: center;
    }
}

@media screen and (min-width: 992px) {
    .reviewstart-component {
        padding: 89px 0 116px;
        img {
            width: 386px;
            margin-left: auto;
        }
        h1 {
            flex: 1;
            font-size: 52px;
            line-height: 64px;
            max-width: calc(100% - 430px);
        }
    }
}

@media screen and (max-width: 991px) {
    .reviewstart-component {
        padding: 75px 0 64px;
        .container {
            flex-direction: column-reverse;
            img {
                max-width: 320px;
            }
            h1 {
                font-size: 24px;
                line-height: 36px;
                margin-top: 37px;
                text-align: center;
            }
        }
    }
}