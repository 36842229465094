@import '../../theme.scss';

.accordion-component {
    flex: 1;
    padding: 26px 0;
    .title-area {
        display: flex;
        pointer-events: auto;
        cursor: pointer;
        .accordion-title {
            flex: 1;
            max-width: calc(100% - 35px);
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
        }
        .svg-icon {
            fill: $secondary-color;
            margin-right: 17px;
            align-items: flex-start;
            padding-top: 4px;
            &.opened {
                fill: $primary-color;
            }
            div {
                align-items: flex-start;
            }
        }
    }
    .accordion-content {
        transition: height 200ms 200ms, opacity 200ms 0ms;
        opacity: 0.0;
        height: 0px;
        overflow: hidden;
        display: block;
        padding-left: 35px;
        
        &.active {
            opacity: 1.0;
            height: initial;
            padding-top: 17px;
            transition: height 200ms 0ms, opacity 200ms 100ms;
        }
    }
}