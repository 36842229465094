@import '../../../theme.scss';

.pricingTables-component {
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
    }
    .item-content {
        .title-content {
            height: 150px;
            background-color: $white900;
            border-top: solid 5px $secondary-color;
        }
        .price-content {
            height: 100px;
            background-color: $gray400;
            font-size: 2rem;
            color: $secondary-color;
            border-top: 1px solid $gray600;
            border-bottom: 1px solid $gray600;         
        }
        .price-active {
            background-color: $secondary-color;
            color: $white900;
            border-bottom: 1px solid $green800;            
        }
        .description-content {
            background-color: $gray300;
            height: 90px;
            font-size: 1.125rem;
            text-align: center;
            padding: 15px 10px 0 10px;
        }
        .btn-content  {
            background-color: $gray300;
            height: 120px;
            .buy-button {
                height: 60px;
                color: $white900;
                font-size: 1.25rem;
                line-height: 1.25rem;
                background-color: $secondary-color;
                text-align: center;
                padding: 0 20px;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .pricingTables-component {
        padding: 90px 0 90px;
        background: url('../../../Assets/Home/reviews-bg.png') no-repeat;
        background-position: center;
        background-size: cover;
        .item-content {
            min-width: 250px;
            .btn-content  {
                .buy-button {
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .pricingTables-component {
        padding: 60px 0 60px;
        background: url('../../../Assets/Home/reviews-bg.png') no-repeat;
        background-position: center;
        background-size: cover;
        .item-content {
            width: 100%;
            margin-top: 20px;
            .btn-content  {
                .buy-button {
                    width: 80%;
                }
            }
        }
    }
}