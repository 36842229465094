@import '../../../theme.scss';

.tutorSignupHeader-component {
    margin-top: 20px;
    .main-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .login-button {
            cursor: pointer;
            background-color: $secondary-color;
            color: $white900;
        }
    }
}

@media screen and (min-width: 992px) {
    .tutorSignupHeader-component {
       
        .main-content {
            .login-button {
                font-size: 20px;
                padding: 7px 15px;
                border-radius: 7px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .tutorSignupHeader-component {
        .main-content {
            .login-button {
                font-size: 15px;
                padding: 5px 10px;
                border-radius: 3px;
            }
        }        
    }
}