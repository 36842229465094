@import '../../theme.scss';

@keyframes courseSidebarShow {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes courseSidebarHide {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.course-sidebar-component {
  .course-sidebar-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 20;
    background: none;
    height: 100vh;
    font-size: 15px;
    color: $primary-color;
    display: flex;
    flex-direction: row-reverse;
    pointer-events: none;

    .course-sidebar-block {
      flex: 1;
      height: 100%;
      display: none;
      pointer-events: auto;
      background: rgba(12, 2, 29, 0.7);
    }

    .course-sidebar-content {
      width: 100%;
      max-width: 1117px;
      margin-right: -100%;
      height: 100%;
      max-height: 100%;
      background: white;
      pointer-events: auto;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .course-sidebar-detail {
        padding: 0;
      }
    }
  }

  &.active {
    .course-sidebar-block {
      display: block;
    }

    .course-sidebar-content {
      margin-right: 0;
      animation: courseSidebarShow 0.2s ease-out;
    }
  }

  &.inactive {
    .course-sidebar-block {
      display: none;
    }

    .course-sidebar-content {
      margin-right: -100%;
      animation: courseSidebarHide 0.2s ease-out;
    }
  }
}