@import '../../theme.scss';

.pricing-page {
    .btn-2 {
        text-decoration: none;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .pricing-header {
        color: white;
        background: url('../../Assets/Pricing/bg.png') no-repeat;
        h1 {
            text-align: center;
        }
        .toggle-area {
            p {
                line-height: 30px;
                &.left-option {
                    font-weight: bold;
                }
            }
            .option-toggle {
                margin: 0 19px;
            }
        }
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .period-row {
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.3);
            padding: 5px;
            max-width: 100%;
            overflow-x: auto;
            .period {
                padding: 13px 0;
                min-width: 146px;
                border-radius: 8px;
                cursor: pointer;
                font-size: 14px;
                font-weight: bold;
                color: white;
                white-space: nowrap;
                &.active {
                    background: white;
                    color: $primary-color;
                }
            }
        }
    }
    .pricing-body {
        .pricing-container {
            display: flex;
            background: none;
            .coding-class {
                box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.09);
                padding: 24px 30px 30px;
                .sub-header {
                    text-align: center;
                    border-bottom: 1px solid rgba(95, 70, 193, 0.1);
                    padding-bottom: 22px;
                    h2 {
                        font-size: 14px;
                        line-height: 24px;
                        margin-bottom: 21px;
                        text-transform: uppercase;
                    }
                    h3 {
                        font-size: 38px;
                        line-height: 40px;
                        color: #712ABC;
												align-items: baseline;
												white-space: normal;
												span {
													display: inline-block;
													&.unit {
														font-size: 15px;
														font-weight: normal;
													}
												}
												.ignore {
													font-size: 25px;
													text-decoration: line-through;
													margin-right: 7px;
													opacity: 0.4;
													font-weight: 500;
												}
                    }
                }
                .sub-body {
                    padding-top: 24px;
                    .sub-item {
                        margin-bottom: 17px;
                        .svg-icon {
                            fill: #712ABC;
                            margin-right: 9px;
                        }
                        p {
                            font-weight: 500;
                        }
                    }
                    .btn-link {
                        height: 55px;
                    }
                }
                &.private {
                    .sub-body {
                        .btn-link {
                            background: rgba(22, 211, 143, 0.08);
                            color: $secondary-color;
                        }
                    }
                }
                &.sibiling {
                    .sub-header {
                        h3 {
                            color: #2986F8;
                        }
                    }
                    .sub-body {
                        .btn-link {
                            &.disabled {
                                color: white;
                            }
                        }
                        .sub-item {
                            .svg-icon {
                                fill: #2986F8;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .pricing-page {
        .pricing-header {
            height: 577px;
            margin-top: -87px;
            padding-top: 87px;
            background-size: 100% 100%;
            background-position: center;
            h1 {
                margin: 32px 0 20px;
                font-size: 52px;
            }
            .period-row {
                margin-top: 33px;
            }
        }
        .pricing-body {
            .pricing-container {
                justify-content: center;
                margin-top: -150px;
                margin-bottom: 53px;
                .coding-class {
                    width: calc(50% - 22.5px);
                    max-width: 428px;
                    background: white;
                    margin-right: 45px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .pricing-page {
        .pricing-header {
            height: 464px;
            margin-top: -70px;
            margin-bottom: 75px;
            padding-top: 70px;
            background-size: cover;
            background-position: left top;
            h1 {
                margin: 29px 0 33px;
                font-size: 24px;
                line-height: 36px;
            }
            .period-row {
                margin-top: 48px;
            }
        }
        .pricing-body {
            .pricing-container {
                flex-direction: column;
                margin-top: -157px;
                .coding-class {
                    width: 100%;
                    max-width: 100%;
                    background: white;
                    &:first-child {
                        margin-bottom: 45px;
                    }
                }
            }
        }
    }
}