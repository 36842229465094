@import '../../../theme.scss';

.parentingHeader-component {
    background-color: $primary-color;

    .social {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .parentingHeader-component {
        margin-top: -87px;
        padding: 30px 0;
        .header-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 991px) {
    .parentingHeader-component {
        margin-top: -70px;
        padding: 20px 0;
    }
}