@import '../../../theme.scss';

.iGenius-landing-page {
  background: $gray700;

  h1 {
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    text-align: center;
    color: $white900
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .iGenius-landing-page {
    h1 {
      font-size: 40px;
      line-height: 40px;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
}