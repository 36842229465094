@import '../../theme.scss';

.forgotpassword-page {
    background: white;
    height: 100vh;
    .container {
        height: 100%;
    }
    .main-content {
        flex: 1;
    }
    .primary-btn {
        text-transform: uppercase;
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .sign-in-area {
        position: relative;
        .mobile-bg {
            max-width: 351px;
        }
        .sign-in-form {
            position: absolute;
            .explain {
                text-align: center;
            }
        }
        #section-2 {
            .input-form {
                justify-content: space-between;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance:textfield;
            }

            .code-input {
                height: 100%;
                text-align: center;
                padding: 0;
                border: none;
                outline: none;
                background: #F6F5F9;
                border-radius: 10px;
                color: #330D63;
                font-size: 22px;
                &:focus {
                    border: 1px solid $secondary-color;
                }
            }

            .btn-area {
                justify-content: space-between;
                width: 100%;
                .primary-btn {
                    text-align: center;
                    &:first-child {
                        background: rgba(22, 211, 143, 0.08);
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .forgotpassword-page {
        margin-top: -87px;
        padding-top: 15px;
        .sign-in-form {
            left: 70px;
            top: 85px;
            width: 338px;
            h1 {
                font-size: 22px;
                line-height: 24px;
                margin-bottom: 16px;
            }
            .input-form {
                margin-bottom: 15px;
                height: 60px;
                width: 100%;
            }
            .primary-btn {
                height: 60px;
                width: 100%;
            }
        }
        #section-1 {
            .explain {
                margin-bottom: 21px;
            }
        }
        #section-2 {
            .explain {
                margin-bottom: 19px;
            }
            .code-input {
                width: 73px;
            }
            .primary-btn {
                width: calc(50% - 7px);
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .forgotpassword-page {
        margin-top: -70px;
        padding-top: 11px;
        .sign-in-form {
            left: 33px;
            top: 160px;
            width: 286px;
            h1 {
                font-size: 24px;
                line-height: 42px;
                margin-bottom: 3px;
            }
            .input-form {
                margin-bottom: 10px;
                height: 55px;
                width: 100%;
            }
            .primary-btn {
                height: 55px;
                width: 100%;
            }
            .explain {
                line-height: 28px;
            }
        }
        #section-1 {
            .explain {
                margin-bottom: 13px;
            }
        }
        #section-2 {
            .explain {
                margin-bottom: 13px;
            }
            .code-input {
                width: 64px;
            }
            .primary-btn {
                width: calc(50% - 5px);
            }
        }
    }
}