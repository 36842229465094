@import '../../../theme.scss';

.aboutRoland-component {
    background-color: $primary-color;

    color: white;
    h4 {
        font-size: 1.375rem;
    }
    p {
        font-size: 1.025rem;
        margin-top: 7px;
    }
    .roland-image {
        width: 100%;
    }
}

@media screen and (min-width: 992px) {
    .aboutRoland-component {
        padding: 90px 0 90px;
        // background: url('../../../Assets/Home/reviews-bg.png') no-repeat;
        // background-position: center;
        // background-size: cover;
              
    }
}

@media screen and (max-width: 991px) {
    .aboutRoland-component {
        padding: 60px 0 60px;
        // background: url('../../../Assets/Home/reviews-bg.png') no-repeat;
        // background-position: center;
        // background-size: cover;
        h4 {
            text-align: center;
            margin-top: 30px;
        }
    }
}