@import '../../theme.scss';

.startbutton-component {
    position: relative;
    font-size: 20px;
    .btn-area {
        cursor: pointer;
        background-color: $secondary-color;
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        .btn-text {
            flex: 1;
            border-right: 2px solid white;
            color: white;
            font-weight: bold;
        }
        .btn-element {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .number-color {
                color: $secondary-color;
                font-size: 5px;
            }
        }
    }

    .disable-btn {
        background-color: $forth-color;
    }
}

@media screen and (min-width: 992px) {
    .startbutton-component {
        width: 300px;
    }
}

@media screen and (max-width: 991px) {
    .startbutton-component {
        width: 285px;
    }
}