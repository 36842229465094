@import '../../../theme.scss';

@keyframes contentShow {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.homestart-component {
  color: white;

  .box-item {
    background: $secondary-color;
    max-width: 207px;
    width: 100%;
    font-weight: bold;
    color: white;
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px;
  }

  .sub-title {
    font-weight: 300;
  }

  h1 {
    animation: contentShow 0.3s ease-out;
  }
}

@media screen and (min-width: 992px) {
  .homestart-component {
    height: 705px;
    margin-top: -87px;
    padding-top: 87px;
    background: url('../../../Assets/Home/bg.png') no-repeat;
    background-position: center bottom;
    background-size: 100% 100%;

    h1 {
      font-size: 52px;
      line-height: 64px;
      margin-top: 41px;
    }

    .sub-title {
      font-size: 52px;
      line-height: 64px;
    }

    p {
      margin-top: 21px;
      font-size: 20px;
      line-height: 30px;
    }

    .box-item {
      margin-top: 36px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 991px) {
  .homestart-component {
    height: 693px;
    margin-top: -70px;
    padding-top: 70px;
    background: url('../../../Assets/Home/bg-mobile.png') no-repeat;
    background-position: right bottom;
    background-size: cover;
    text-align: center;

    h1 {
      font-size: 24px;
      line-height: 36px;
      margin-top: 62px;
    }

    .sub-title {
      font-size: 24px;
      line-height: 36px;
    }

    p {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
    }

    .box-item {
      margin: 23px auto 0;
      height: 55px;
    }
  }
}