@import '../../../theme.scss';

.whyDid-component {
    background-color: $gray400;
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 20px;
    }
    p {
        font-size: 1.125em;
    }
    .main-content {
        .title {
            font-weight: 600;
        }
        .more-write-content {
            padding: 10px 0;
            margin: 15px 0;
            border-top: solid 1px $primary-color;
            border-bottom: solid 1px $primary-color;
        }
        .logo-content {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: solid 1px $primary-color;
        }
    }
}

@media screen and (min-width: 992px) {
    .whyDid-component {
        padding: 90px 0;
        .main-content {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: 991px) {
    .whyDid-component {
        padding: 60px 0;
        .logo-content {
            margin-left: auto;
            margin-right: auto;
        }
        .description {
            text-align: center;
        }
    }
}