@import '../../../theme.scss';

.aboutIcode-component {
    background-color: $gray400;
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
    }
    p {
        font-size: 1.125em;
        text-align: center;
    }
}

@media screen and (min-width: 992px) {
    .aboutIcode-component {
        padding: 90px 0;
        .main-content {
            max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: 991px) {
    .aboutIcode-component {
        padding: 60px 0;
        
    }
}