@import '../../theme.scss';

.contactus-page {
    display: flex;
    flex-direction: column;
    color: $primary-color;

    .contactus-container {
        display: flex;
        flex-direction: column;

        .title {
            text-align: center;
            font-weight: bold;
        }

        .main-row {
            margin: 0;
        }

        .right-area {
            .map-wrapper {
                width: 100%;
                padding-top: 75%;
                position: relative;

                .map-container {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .contactus-page {
        .contactus-container {
            max-width: 930px;
        }
    }
}

@media screen and (min-width: 992px) {
    .contactus-page {
        padding: 97px 0 120px;
        .contactus-container {
            .title {
                font-size: 45px;
                line-height: 57px;
                margin-bottom: 94px;
            }

            .main-row {
                padding: 30px 0;
            }

            .left-area {
                font-size: 20px;
                line-height: 34px;
                padding: 0 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .right-area {
                padding: 0 30px 0 0;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .contactus-page {
        padding: 66px 0 74px;
        .contactus-container {
            .title {
                font-size: 34px;
                line-height: 45px;
                margin-bottom: 62px;
            }

            .main-row {
                padding: 15px 0;
            }

            .left-area {
                font-size: 15px;
                line-height: 24px;
                padding: 0 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 36px;
            }

            .right-area {
                padding: 0 15px;
            }
        }
    }
}