@import '../../../theme.scss';

.technologyarea-component {
    h1, h2 {
        text-align: center;
    }
    .container {
        max-width: 100%;
    }
    .tech-row {
        border-radius: 8px;
        background: rgba(51, 13, 99, 0.03);
        padding: 5px;
        width: fit-content;
        .tech {
            border-radius: 8px;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            color: rgba(51, 13, 99, 0.45);
            width: 120px;
            height: 40px;
            &.active {
                background: white;
                color: $primary-color;
            }
        }
    }
    .tech-content {
        display: flex;
    }
    .tech-item {
        padding: 18px 11px 18px 27px;
        background: white;
        border: 1px solid $border-color;
        line-height: 24px;
        margin-bottom: 30px;
        width: fit-content;
        position: relative;
        box-shadow: -40px 40px 200px rgba(42, 26, 127, 0.19);
        .border {
            width: 4px;
            position: absolute;
            left: 0;
            bottom: 0;
            top: 0;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            &.border-1 {
                background: #712ABC;
            }
            &.border-2 {
                background: #5B53F3;
            }
            &.border-3 {
                background: #4FABDE;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .video-area {
        position: relative;
        .player-area {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: none;
            .play-btn {
                position: absolute;
                z-index: 10;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                width: 45px;
                height: 45px;
            }
            video {
                object-fit: fill;
                position: relative;
                border-radius: 10px;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .technologyarea-component {
        padding: 94px 0 90px;
        h1 {
            font-size: 36px;
            line-height: 24px;
            margin-bottom: 42px
        }
        .tech-row {
            margin: 42px auto 40px auto;
        }
        h2 {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
        }
        .tech-content {
            .video-area {
                width: 520px;
                padding-left: 520px;
                &.learning {
                    height: 269px;
                }
            }
            .tech-list {
                width: calc(50% - 260px);
                &.left {
                    padding-right: 20px;
                    .tech-item {
                        margin-left: auto;
                    }
                    .item-2 {
                        margin-right: 11px;
                    }
                    .item-3 {
                        margin-right: 111px;
                        white-space: nowrap;
                    }
                    &.learning {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .item-1 {
                            margin-right: 43px;
                        }
                    }
                }
                &.right {
                    padding-left: 20px;
                    .item-2, .item-3 {
                        margin-left: 66px;
                    }
                    &.learning {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .item-2 {
                            margin-left: 28px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .technologyarea-component {
        padding: 66px 0 75px;
        h1 {
            font-size: 24px;
            line-height: 44px;
            margin-bottom: 30px;
        }
        .tech-row {
            margin: 30px auto 40px auto;
        }
        h2 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 37px;
        }
        .tech-content {
            flex-direction: column;
            .video-area {
                margin: 30px 0 36px;
                padding-top: 50%;
            }
            .tech-list {
                &.left {
                    .item-2 {
                        margin-left: auto;
                    }
                }
                &.right {
                    .item-2 {
                        margin-left: 134px;
                    }
                    .item-3 {
                        margin-left: 27px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .technologyarea-component {
        .tech-content {
            .tech-list {
                &.left {
                    padding-right: 47px !important;
                }
                &.right {
                    padding-left: 47px !important;
                }
            }
        }
    }
}