@import '../../../theme.scss';

.benefits-component {
    h3 {
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 992px) {
    .benefits-component {
        padding: 90px 0 90px;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 991px) {
    .benefits-component {
        padding: 60px 0 60px;
        
    }
}