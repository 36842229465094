@import '../../../theme.scss';

.student-signup-footer-component {
    color: white;
    p {
        font-size: 12px;
    }
}

@media screen and (min-width: 992px) {
    .student-signup-footer-component {
        padding: 30px 0;
        background: url('../../../Assets/StudentSignup/student_stignup_footer_bg.png') no-repeat;
        background-position: center;
        background-size: cover;
        .right-content {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

@media screen and (max-width: 991px) {
    .student-signup-footer-component {
        padding: 0px 15px;
        background: url('../../../Assets/StudentSignup/student_stignup_footer_bg_mobile.png') no-repeat;
        background-position: center;
        background-size: cover;
        .left-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
        }
        .right-content {
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: solid 1px $gray200;
            padding: 20px 0;
        }
    }
}