@import '../../../theme.scss';

.universities-component {
    background: #F6F5F9CD;
    h1 {
        line-height: 24px;
    }
    h1, h2, p {
        text-align: center;
    }
    .owl-theme {
        .owl-nav {
            .owl-prev, .owl-next {
                outline: none;
                &:hover {
                    background: none;
                }
            }
        }
        .owl-stage-outer {
            padding-top: 30px;
            padding-bottom: 30px;
            .owl-stage {
                display: flex;
            }
        }
    }   
}

@media screen and (min-width: 992px) {
    .universities-component {
        padding: 80px 0;
        h1 {
            font-size: 36px;
            line-height: 24px;
        }
        .owl-theme {
            .owl-nav {
                position: absolute;
                display: flex;
                justify-content: space-between;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                left: -30px;
                right: -30px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .universities-component {
        padding: 40px 0;

        h1 {
            font-size: 24px;
            text-align: center;
            line-height: 36px;
        }
        .owl-theme {
            .owl-nav {
                .owl-prev, .owl-next {
                    outline: none;
                    &:hover {
                        background: white;
                    }
                }
                .owl-prev {
                    margin: 0 15px 0 5px;
                }
                .owl-next {
                    margin: 0 5px 0 15px;
                }
            }
        }
    }
}