@import '../../theme.scss';

.inputbox-component {
    // height: 100%;
    height: 60px;
    width: 100%;
    background: #F6F5F9;
    padding-left: 20px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    &.focused {
        border: 1px solid $secondary-color;
    }
    .svg-icon {
        fill: #A898BD;
        &:first-child {
            margin-right: 11px;
        }
    }
    ::placeholder {
        color: #A898BD;
    }
    .input-area {
        flex: 1;
        min-width: 100px;
        .upon-placeholder {
            font-size: 12px;
            line-height: initial;
            color: #330D63;
        }
    }
    .custom-input {
        border: none;
        outline: none;
        background: inherit;
        padding-right: 11px;
        padding-left: 0;
        color: #330D63;
        flex: 1;
        line-height: normal;
    }
    .last-icon {
        margin-right: 20px;
        margin-left: 0;
        fill: #A898BD;
        cursor: pointer;
    }
    .forgot-password {
        color: $secondary-color;
        margin-right: 20px;
    }
    .custom-flag {
        padding: 0;
        margin-right: 11px;
        .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
            min-width: 200px;
            padding: 0;
        }
        img {
            top: 0;
        }
    }
    
}

.inputbox-container-error {
    border: 2px solid red;
}

@media screen and (min-width: 992px) {
    .inputbox-component {
        .custom-input, .forgot-password {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 991px) {
    .inputbox-component {
        .custom-input, .forgot-password {
            font-size: 14px;
        }
    }
}