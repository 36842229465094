.storyheader-component {
    background: pink;
    color: white;
    .left-col {
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .storyheader-component {
        background: url('../../../Assets/Review/bg.png') no-repeat;
        background-size: cover;
        background-position: center;
        h1 {
            font-size: 36px;
            line-height: 46px;
        }
        .left-col {
            align-items: center;
        }
        .avatar {
            width: 326px;
            margin: 57px 0 -57px 0;
        }
    }
}

@media screen and (max-width: 991px) {
    .storyheader-component {
        padding: 75px 0 63px;
        background: url('../../../Assets/Review/bg-mobile.png') no-repeat;
        background-size: cover;
        background-position: left center;
        h1 {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
        }
        .left-col {
            justify-content: center;
        }
        .right-col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
        .avatar {
            width: 190px;
            margin-bottom: 37px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .storyheader-component {
        background-size: 100% 100% !important;
    }
}