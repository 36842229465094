@import '../../../theme.scss';

.studentSignupStart-component {
    color: white;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .call-btn {
            background-color: $white900;
            border-radius: 5px;
            padding: 5px 7px;
            cursor: pointer;
            .phone-num {
                font-size: 12px;
                color: $primary-color;
            }
        }
    }
    .sub-title {
        font-weight: 300;
    }
    h1 {
        animation: contentShow 0.3s ease-out;
    }
    .main-content {
        margin-top: 70px;
        .kenya-flag {
            width: 70px;
        }

        .phone-input {
            padding: 0;
        }
        .select-age-title {
            font-size: 16px;
            font-weight: bold;
            margin: 10px 0 10px;
        }
        .form-content {
            color: $primary-color;
            .age-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                .select-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    background: #F6F5F9;
                    border-radius: 10px;
                    height: 50px;
                    width: calc(20% - 10px);
                    cursor: pointer;
        
                    &:nth-child(even) {
                        margin-right: 0;
                    }
                    &.active {
                        border: 1.5px solid $secondary-color;
                        .selected {
                            display: flex;
                        }
                    }
                    .selected {
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        right: 0;
                        top: 0;
                        border-top-right-radius: 10px;
                        background: $secondary-color;
                        border-bottom-left-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        display: none;
                        .svg-icon {
                            fill: white;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .studentSignupStart-component {
        height: 605px;
        margin-top: -87px;
        padding-top: 15px;
        background: url('../../../Assets/StudentSignup/start_bg.png') no-repeat;
        background-position: center bottom;
        background-size: 100% 100%;

        h1 {
            font-size: 48px;
            line-height: 64px;
            margin-top: 41px;
        }
        h2 {
            font-size: 24px;
            line-height: 24px;
            text-align: center;
        }
        .sub-title {
            font-size: 52px;
            line-height: 64px;
        }
        p {
            font-size: 28px;
            line-height: 30px;
        }
        .form-content {
            width: 100%;
            background-color: $white900;
            border-radius: 10px;
            padding: 20px 15px;

            .input-form {
                margin-bottom: 15px;
                height: 50px;
                width: 100%;
            }
        }
        .right-img-content {
            padding: 0;
            margin-top: 40px;
            margin-left: -65px;
            .right-img {
                width: 120%;
            }
        }
        .arrow {
            position: absolute;
            top: -25px;
            right: -67px;
            z-index: 9;
        }
    }
}

@media screen and (max-width: 991px) {
    .studentSignupStart-component {
        height: 693px;
        margin-top: -70px;
        padding-top: 30px;
        background: url('../../../Assets/StudentSignup/start_bg_mobile.png') no-repeat;
        background-position: right bottom;
        background-size: cover;
        text-align: center;
        h1 {
            font-size: 24px;
            line-height: 36px;
        }
        h2 {
            font-size: 20px;
            text-align: center;
        }
        .sub-title {
            font-size: 24px;
            line-height: 36px;
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
        .form-content {
            background-color: $white900;
            border-radius: 10px;
            padding: 15px;
            margin-top: 20px;
            .input-form {
                margin-bottom: 10px;
                height: 55px;
                width: 100%;
            }
        }
    }
}