@import '../../theme.scss';

.signin-page {
    background: white;
    height: 100vh;
    .container {
        height: 100%;
    }
    .main-content {
        flex: 1;
    }
    .sign-in-area {
        position: relative;
        .mobile-bg {
            max-width: 351px;
        }
        .sign-in-form {
            position: absolute;
        }
    }
    .primary-btn {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

@media screen and (min-width: 992px) {
    .signin-page {
        margin-top: -87px;
        padding-top: 15px;
        .sign-in-form {
            left: 70px;
            top: 85px;
            width: 338px;
            h1 {
                font-size: 22px;
                line-height: 24px;
                margin-bottom: 7px;
            }
            .input-form {
                margin-bottom: 15px;
                height: 60px;
                width: 100%;
            }
            .primary-btn {
                height: 60px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .signin-page {
        margin-top: -70px;
        padding-top: 11px;
        .sign-in-form {
            left: 33px;
            top: 160px;
            width: 286px;
            h1 {
                font-size: 24px;
                line-height: 42px;
                margin-bottom: 9px;
            }
            .input-form {
                margin-bottom: 10px;
                height: 55px;
                width: 100%;
            }
            .primary-btn {
                height: 55px;
                width: 100%;
            }
        }
    }
}