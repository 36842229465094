@import '../../theme.scss';

.feedback-component {
  background: white;
  width: 100%;
  color: $primary-color;
  box-shadow: -40px 40px 170px rgba(42, 26, 127, 0.05);

  .feedback-header {
    display: flex;

    .avatar {
      width: 66px;
      height: 66px;
      margin-right: 15px;
    }

    .reviewer-info {
      flex: 1;
    }

    h3 {
      font-size: 14px;
      margin-bottom: 4px;

      img {
        margin-left: 4px;
        width: 17px;
        height: 17px;
      }
    }

    .address {
      font-size: 12px;
      margin-bottom: 6px;

      .custom-flag {

        ::before,
        ::after {
          display: none;
        }

        padding: 0;
        margin-left: 5px;

        .flag-select__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          margin: 0;
          padding: 0;

          .flag-select__option--placeholder {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            width: 15px;
            height: 15px;

            .flag-select__option__icon {
              top: 0;
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  h2 {
    margin: 27px 0 6px;
  }

  .feedback {
    font-size: 16px;
    line-height: 26px;
    height: 182px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    margin: 27px 0 6px;
  }

  .continue-btn {
    cursor: pointer;
    color: $secondary-color;
    margin-top: 4px;
  }
}

@media screen and (min-width: 992px) {
  .feedback-component {
    padding: 30px 20px 20px 30px;
    max-width: 370px;

    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 991px) {
  .feedback-component {
    padding: 30px 9px 24px 30px;
    min-width: 335px;

    h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}