@import '../../../theme.scss';

.ourfun-component {    
    h1 {
        line-height: 36px;
    }
    h1, h2, p {
        text-align: center;
    }
}

@media screen and (min-width: 992px) {
    .ourfun-component {
        background: url('../../../Assets/Home/workbg.png') no-repeat;
        background-size: cover;
        background-position: center;
        padding: 90px 0;     
        h1 {
            font-size: 36px;
            margin-bottom: 56px;
        }
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 40px 0 9px
        }
        p {
            line-height: 26px;
            min-height: 140px;
        }
        .sub-col {
            padding-bottom: 50px;
            &:hover {
                padding-bottom: 0;
            }
        }
        .work-block {
            max-width: 350px;
            width: 100%;
            background: white;
            margin-bottom: auto;
            border-radius: 10px;
            cursor: pointer;
            .number-outter {
                width: 62px;
                height: 62px;
                border: 1px solid #5F46C14C;
                margin: -31px auto 0;
                box-shadow: 0 0 20px #2A1A7F33;
                background: white;
                display: none;
                .number-in {
                    width: 48px;
                    height: 48px;
                    font-size: 20px;
                    font-weight: bold;
                    color: white;
                    line-height: 24px;
                    background: #5F46C1;
                }
            }
            &:hover {
                margin-bottom: -29px;
                box-shadow: 0 20px 100px #2A1A7F17;
                .number-outter {
                    display: flex;
                }
                .work-content {
                    margin-top: 30px;
                }
            }
            .work-content {
                padding: 0 25px 23px 25px;
                img {
                    height: 104px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .ourfun-component {
        padding: 70px 0;
        
        h1 {
            font-size: 36px;
            margin-bottom: 41px;
        }        
        img {
            width: 50%;
        }
        .number-outter {
            display: none !important;
        }
        .work-block {
            width: 100%;
        }
        .sub-col {
            margin-bottom: 38px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h2 {
            margin: 26px 0 17px;
            font-size: 20px;
        }
    }
}