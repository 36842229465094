@import '../../theme.scss';

@keyframes mobilesidebarShow {
    0% {  transform: translateX(100%); }
    100% { transform: translateX(0); }
}
  
@keyframes mobilesidebarHide {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

.mobilesidebar-component {
    .mobilesidebar-wrapper {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 20;
        background: none;
        height: 100vh;
        font-size: 15px;
        display: flex;
        pointer-events: none;
        .mobilesidebar-block {
            flex: 1;
            height: 100%;
            display: none;
            pointer-events: auto;
        }
        .mobilesidebar-content {
            background: white;
            width: 100%;
            margin-left: auto;
            margin-right: -100%;
            height: 100%;
            pointer-events: auto;
            padding-bottom: 35px;

            .container {
                height: 100%;
                .sidebar-header {
                    height: 70px;
                    justify-content: space-between;
                    .logo {
                        margin-top: -11px;
                    }
                    .svg-icon {
                        fill: $primary-color;
                    }
                }
                .box-menu {
                    width: 100%;
                    height: 55px;
                    font-size: 16px;
                    line-height: 24px;
                    border-radius: 10px;
                    font-weight: bold;
                    &.active {
                        text-decoration: none;
                    }
                    &.trial {
                        background: $secondary-color;
                        color: white;
                        margin-top: 22px;
                    }
                    &.signin {
                        background: #E8FBF4;
                        color: $secondary-color;
                        margin-top: auto;
                    }
                }
                .menu-list {
                    margin: 50px 0 10px;
                    max-height: calc(100% - 262px);
                    height: calc(100% - 262px);
                    overflow-y: auto;
                    .sub-menu {
                        width: fit-content;
                        padding-bottom: 5px;
                        margin-bottom: 28px;
                        min-height: fit-content;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .svg-icon {
                            fill: $primary-color;
                            margin-right: 11px;
                        }
                        p {
                            color: $primary-color;
                            font-size: 22px;
                            font-weight: bold;
                        }
                        &.active {
                            border-bottom: 2px solid $secondary-color;
                            .svg-icon {
                                fill: $secondary-color;
                            }
                            p {
                                color: $secondary-color;
                            }
                        }
                    }
                    .sub-list-header {
                        min-height: fit-content;
                        .last-icon {
                            fill: $primary-color;
                            margin-left: 5px;
                            &.opend {
                                transform: rotate(0);
                            }
                            &.closed {
                                transform: rotate(180deg);
                            }
                        }
                        &.opend {
                            margin-bottom: 20px;
                        }
                    }
                    .sub-list {
                        height: 0;
                        overflow: hidden;
                        opacity: 0;
                        min-height: 0;
                    }
                    .sub-list-item {
                        margin-left: 54px;
                        width: fit-content;
                        color: $primary-color;
                        font-size: 18px;
                        margin-bottom: 21px;
                        min-height: fit-content;
                        &.active {
                            border-bottom: 2px solid $secondary-color;
                            min-height: fit-content;
                            p {
                                color: $secondary-color;
                            }
                        }
                        &:last-child {
                            margin-bottom: 35px;
                        }
                    }
                }
            }
        }
    }

    &.active {
        .mobilesidebar-block {
            display: block;
        }
        .mobilesidebar-content {
            margin-right: 0;
            animation: mobilesidebarShow 0.2s ease-out;
        }
    }
    
    &.inactive {
        .mobilesidebar-block {
            display: none;
        }
        .mobilesidebar-content {
            margin-right: -100%;
            animation: mobilesidebarHide 0.2s ease-out;
        }
    }
}

@media screen and (min-width: 992px) {
    .mobilesidebar-component {
        display: none;
    }
}