@import '../../../theme.scss';

.whyIcodeEdu-component {
    background-color: $gray200;
    h1 {
        line-height: 24px;
    }
    h1, h2 {
        text-align: center;
    }
    p {
        font-size: 16px;
    }
    .work-block {
        background: white;
        border-radius: 10px;            
        .work-content {
            padding: 10px 15px;
        
            .logo-container {
                .logo-content {
                    width: 40px;
                    height: 40px;
                    background-color: $blue700;
                    border-radius: 5px;
                    margin-right: 15px;
                }
            }
        }
    }
    .load-more-btn {
        width: 150px;
        height: 60px;
        background-color: rgba(22, 211, 143, 0.08);
        font-size: 18px;
        color: $secondary-color;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
    }
    .brandbot-container {
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 992px) {
    .whyIcodeEdu-component {
        padding: 90px 0;
       
        h1 {
            font-size: 36px;
            margin-bottom: 56px;
        }
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 40px 0 9px
        }
        p {
            line-height: 26px;
        }
        .sub-col {
            padding-bottom: 50px;
            &:hover {
                padding-bottom: 0;
            }
        }
        .work-block {
            max-width: 350px;
            min-height: 100px;
            max-height: 100px;
            width: 100%;
            margin-bottom: auto;
            cursor: pointer;
            

            &:hover {
                box-shadow: 0 20px 100px #2A1A7F17;
                max-height: 300px;
                
                .work-content {
                    // margin-top: 30px;
                    p {
                        display: block;
                        font-size: 20px;
                    }
                }
            }

            .work-content {
                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        .brandbot {
            margin-right: -50px;
            margin-top: -150px;
            z-index: 3; 
        }
    }
}

@media screen and (max-width: 991px) {
    .whyIcodeEdu-component {
        padding: 67px 0 75px;
        
        h1 {
            font-size: 36px;
            margin-bottom: 41px;
        }
        .work-block {
            width: 100%;
        }
        .sub-col {
            margin-bottom: 38px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h2 {
            margin: 26px 0 17px;
            font-size: 20px;
        }
        .load-more-btn {
            margin-top: 20px;
        }
    }
}